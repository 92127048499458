import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import WaveSurfer from 'wavesurfer.js';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  focus: any;
  focus1: any;
  public wavesurfer: WaveSurfer;

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {

    console.log(this.route.snapshot.paramMap.get('id'))

    if (this.route.snapshot.paramMap.get('id') !== null) {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'text/plain');
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
      this.httpClient
        .post(
          //'http://localhost:5000/vovoice-bda7c/us-central1/getSound'
          'https://us-central1-vovoice-bda7c.cloudfunctions.net/getSound'
          , {
            soundId: this.route.snapshot.paramMap.get('id'),
          }, { headers: headers })
        .subscribe(data => {
          console.log(data);

          var linGrad = document.createElement('canvas').getContext('2d').createLinearGradient(0, 0, 1000, 128);
          linGrad.addColorStop(0, data['soundData']['color1']);
          linGrad.addColorStop(1, data['soundData']['color2']);


          this.wavesurfer = WaveSurfer.create({
            container: '#waveform',
            waveColor: linGrad,
            responsive: true,
            maxCanvasWidth: 3000,
            cursorWidth: 0,
            interact: false,
            barWidth: data['soundData']['barWidth'],
            barHeight: data['soundData']['barHeight'],
            barGap: data['soundData']['barGap'],

            plugins: [

            ]
          });

          this.wavesurfer.load(data['soundData']['soundFileURL']);

        });
    }

  }


  play() {
    this.wavesurfer.play()
  }
}
